/* eslint-disable */

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { axiosInstanceLive } from '../../../instance/Axios';
import { useNavigate } from 'react-router-dom';
// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { useSelector, useDispatch } from 'react-redux';
import { increment } from 'redux/WalletSlice';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useEffect } from 'react';
import { handleCatchError } from 'utils/CatchHandler';
import BcAgreement from './ProfileSection/BcAgreement';
import { checkMerchantOrBCAPI, checkPermissionGet } from 'utils/API/Merchant APIs/MerchantApiCalls';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import './index.css';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, premiumStatus }) => {
    const balanceW1 = useSelector((state) => state?.wallet?.balance?.roundedNumber);
    const balanceW2 = useSelector((state) => state?.wallet?.balance?.virtualBalance);
    const balanceW3 = useSelector((state) => state?.wallet?.balance?.wallet3Balance);
    const showBc = useSelector((state) => state?.bcAgreement?.bcAgreementShow);
    // const valueW1 = isNaN(balanceW1) ? 0 : balanceW1;
    // const valueW2 = isNaN(balanceW2) ? 0 : balanceW2;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState();
    const [virtualBalanceState, setVirtualBalance] = useState('');
    // console.log('virtualBalanceState', virtualBalanceState);
    const type = localStorage.getItem('type');
    const [isShow, setIsShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isPremiumBc, setIsPremiumBc] = useState(false);
    const [premiumLoading, setPremiumLoading] = useState(false);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const shouldShowW3 = localStorage.getItem('w3status');
    const mode = useSelector((state) => state.theme.mode);

    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const checkIsMerchantOrBc = async () => {
        setPremiumLoading(true);
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const merchantBcCheckResponse = await checkMerchantOrBCAPI(payload);
        const eSignStatus = merchantBcCheckResponse?.data?.isESigned;
        const agentType = merchantBcCheckResponse?.data?.agentType;
        if (agentType != 'Merchant' && eSignStatus === false) {
            localStorage.setItem('shouldshowEsign', true);
        } else {
            localStorage.setItem('shouldshowEsign', false);
        }
        setPremiumLoading(false);
        // setType(agentType);
        // if (agentType === 'Premium BC Class 1' || agentType === 'Premium BC Class 2' || agentType === 'Premium BC Class 3') {
        //     setIsPremiumBc(true);
        // } else {
        //     setIsPremiumBc(false);
        // }
    };
    const checkPermissions = async () => {
        //API TO CHECK PERMISSION FOR SETTLEMENT REQUEST
        try {
            const response = await checkPermissionGet();
            // console.log('check persmission:', response);
            if (response?.data?.status) {
                const dmtType = response?.data?.dmt_type;
                const bc_agent_status = response.data?.bc_agent_status;
                localStorage.setItem('dmtType', dmtType);
                localStorage.setItem('bc_agent_status', bc_agent_status);
                localStorage.setItem('kycKeyStatus', response.data?.matm_kyc_status);
            } else {
                const message = response?.data?.message;
                // globalNotifyError(message ? message : 'Request failed,Please try again.');
            }
            // setIsTrue(false);
        } catch (error) {
            // setIsTrue(false);
            handleCatchError(error);
            console.log(error.message);
        }
    };

    useEffect(() => {
        // fetchBalance();
        if (type === 'merchants') {
            dispatch(fetchWalletBalanceAsync());
            checkPermissions();
        }
    }, [type]);

    const handleClick = () => {
        navigate('merchants/wallet');
        handleClose();
    };
    const handleClickW2 = () => {
        navigate('merchants/transactions');
        handleClose();
    };
    const handleClickW3 = () => {
        navigate('merchants/wallet3');
        handleClose();
    };
    function handleBalance() {
        navigate('/merchants/transactions');
    }
    useEffect(() => {
        const type = localStorage.getItem('type');
        if (type === 'merchants') {
            setIsShow(true);
        }
        checkIsMerchantOrBc();
    }, []);
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    color: '#28282B',
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {showBc && <BcAgreement />}
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: mode === 'dark' ? 'linear-gradient(145deg, #1f1f1f, #0071bc)' : theme.palette.primary.light,
                            color: mode === 'dark' ? '#FFD700' : theme.palette.primary.dark,
                            '&:hover': {
                                background: mode === 'dark' ? 'linear-gradient(145deg, #1f1f1f, #0071bc)' : theme.palette.primary.dark,
                                color: theme.palette.primary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {isShow && !premiumLoading && (
                <>
                    <ButtonBase className="wallet-button" onClick={handleClick} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2,
                                '&:hover': { cursor: 'pointer' }
                            }}
                        >
                            <Chip
                                className={mode === 'dark' ? 'goldenChip' : ''}
                                color="primary"
                                label={`W1 ₹ ${balanceW1 ? balanceW1 : 0}`}
                                // label={`W1 ₹ ${valueW1}`}
                                sx={{
                                    // background: 'linear-gradient(145deg, #0b0b0b, #161616)',
                                    mr: 1,
                                    p: 1,
                                    '&:hover': { cursor: 'pointer' }
                                }}
                            />
                        </Box>
                    </ButtonBase>

                    <ButtonBase className="wallet-button" onClick={handleClickW2} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2,
                                '&:hover': { cursor: 'pointer' }
                            }}
                        >
                            <Chip
                                className={mode === 'dark' ? 'goldenChip' : ''}
                                color="primary"
                                label={`W2 ₹ ${balanceW2 ? balanceW2 : 0}`}
                                // label={`W1 ₹ ${valueW2}`}
                                sx={{ mr: 1, p: 1, '&:hover': { cursor: 'pointer' } }}
                            />
                        </Box>
                    </ButtonBase>
                    {shouldShowW3 === 'true' && (
                        <ButtonBase className="wallet-button" onClick={handleClickW3} sx={{ '&:hover': { cursor: 'pointer' } }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mr: 2,
                                    '&:hover': { cursor: 'pointer' }
                                }}
                            >
                                <Chip
                                    className={mode === 'dark' ? 'goldenChip' : ''}
                                    color="primary"
                                    label={`W3 ₹ ${balanceW3 ? balanceW3 : 0}`}
                                    // label={`W1 ₹ ${valueW2}`}
                                    sx={{ mr: 1, p: 1, '&:hover': { cursor: 'pointer' } }}
                                />
                            </Box>
                        </ButtonBase>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                            '&:hover': { cursor: 'pointer' }
                        }}
                    >
                        <div className="wallet-dropdown">
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleDropdownClick}
                            >
                                Wallets
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem onClick={handleClick}>{`Wallet 1 (₹ ${balanceW1 ? balanceW1 : 0})`}</MenuItem>
                                <MenuItem onClick={handleClickW2}>{`Wallet 2 (₹ ${balanceW2 ? balanceW2 : 0})`}</MenuItem>
                                {shouldShowW3 === 'true' && (
                                    <MenuItem onClick={handleClickW3}>{`Wallet 3 (₹ ${balanceW3 ? balanceW3 : 0})`}</MenuItem>
                                )}
                            </Menu>
                        </div>
                    </Box>
                </>
            )}
            <NotificationSection />
            {!premiumLoading && <ProfileSection />}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
