// import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
import Dashboard from './dashboard';
// import UtilityBillsAndEMI from './utilityBillsAndEMI';
// import other from './other';
// import Booking from './Booking';
// import AceMoneyEarnMore from './AceMoneyEarnMore';
// import Insurance from './Insurance';
// import AccountAndSavings from './AcountAndSavings';
// import CollectPayment from './CollectPayment';
// import RefferAndEarn from './RefferAndEarn';
// ==============================|| MENU ITEMS ||============================== //

const isSubdistributor = localStorage.getItem('isSubdistributor');
const whitelabelStatus = localStorage.getItem('whitelabelStatus');
let filteredMenu = Dashboard;
if (isSubdistributor === 'true') {
    const selectedIds = ['tabler-icons1', 'tabler-icons2', 'tabler-icons3', 'tabler-icons4', 'tabler-icons7', 'tabler-icons8'];

    filteredMenu = {
        ...Dashboard,
        children: Dashboard.children.filter((child) => selectedIds.includes(child.id))
    };
}
// Condition for whitelabelStatus
if (whitelabelStatus === 'true') {
    const excludedIds = ['tabler-icons5', 'tabler-icons6'];

    filteredMenu = {
        ...filteredMenu, // Use the already filteredMenu from the previous condition (if applied)
        children: filteredMenu.children.filter((child) => !excludedIds.includes(child.id))
    };
}
// console.log('Dashboard', Dashboard);

const distributorMenuItems = {
    items: [filteredMenu]
};

export default distributorMenuItems;
