import axios from 'axios';
import { handleTokenSwap } from 'utils/CatchHandler';
import { MPinStatusChecker } from 'utils/mPinStatusChecker';
import { globalNotifyError } from 'views/utilities/AlertToast';
// const BASE_URL = process.env.REACT_APP_BASE_URL;
const currentDomain = window.location.hostname;
// console.log('currentDomain', currentDomain);
// const tempSupportURL = 'https://supportnode.acepe.co.in';
// const tempSupportURL = 'http://localhost:8020';
let baseURL;
let nodeURL;
let supportURL;
//Condition to check the domain  and assign corresponding url
if (currentDomain === 'app.acemoney.in' || currentDomain === 'localhost') {
    //Production address of aceneobank backend and nodejs backend
    baseURL = 'https://aceneobank.com';
    nodeURL = 'https://testnode.acemoney.in';
    supportURL = 'https://supportnode.prod.acemoney.in';
} else if (currentDomain === 'dev-app.acemoney.in') {
    //Development address of aceneobank and nodejs backend
    baseURL = 'https://aceneobank.dev.acepe.co.in';
    nodeURL = 'https://testnode.acepe.co.in';
    // nodeURL = 'http://localhost:8000';
    supportURL = 'https://supportnode.acepe.co.in';
} else if (currentDomain === 'stage-app.acemoney.in') {
    //Staging address of aceneobank and nodejs backend
    baseURL = 'https://aceneobank.stage.acepe.co.in';
    // baseURL = 'https://aceneobank.dev.acepe.co.in';
    nodeURL = 'https://testnode.acepe.co.in'; //TEMPORARLY ADDED
    supportURL = 'https://supportnode.acepe.co.in';
} else {
    // Default URL if the current domain does not match any specific condition
    baseURL = 'https://aceneobank.com/';
    nodeURL = 'https://testnode.acemoney.in';
    supportURL = 'https://supportnode.prod.acemoney.in';
}

// export const axiosInstance = axios.create({
//     baseURL: 'https://aceneobank.dev.acepe.co.in'
// });

export const axiosInstanceLive = axios.create({
    baseURL
});
export const currentBaseURL = baseURL;

export const axiosInstanceNodejs = axios.create({
    // baseURL: 'https://testnode.acemoney.in'
    // baseURL: 'https://testnode.acepe.co.in'
    baseURL: nodeURL
});

export const supportAxiosInstanceNodejs = axios.create({
    baseURL: supportURL
});

axiosInstanceLive.interceptors.response.use((response) => {
    // If the response status is between 200 and 299, return the response
    if (response.status >= 200 && response.status < 300) {
        // console.log(response);
        if (response.status === 201) {
            const message = response?.data?.message;
            if (message) {
                globalNotifyError(message);
                return response;
            }
            if (response?.data?.errors) {
                const errors = response?.data?.errors;
                // Get an array of all values from the errors object
                const errorArrays = Object.values(errors);
                // Access the first array by default
                const firstArray = errorArrays[0];
                // console.log('firstArray', firstArray);
                globalNotifyError(firstArray[0]);
                return response;
            }
        } else {
            return response;
        }
    }

    // Handle specific status codes here
    if (response.status === 404) {
        // Handle 400 Bad Request
        globalNotifyError('The page not found ! 404');
    } else if (response.status === 500) {
        // Handle 500 Internal Server Error
        globalNotifyError('Oops..! Something went wrong.');
    } else if (response.status === 429) {
        globalNotifyError('Oops..! Too many requests !');
        return response;
    } else if (response.status === 451) {
        handleTokenSwap();

        MPinStatusChecker();
        globalNotifyError('Session Timeout');
    }
});

supportAxiosInstanceNodejs.interceptors.response.use((response) => {
    // If the response status is between 200 and 299, return the response
    if (response.data.meta.code >= 200 && response.data.meta.code < 300) {
        // console.log(response);
        if (response.data.meta.code === 201) {
            const message = response?.data?.message;
            if (message) {
                globalNotifyError(message);
                return response;
            }
            if (response?.data?.errors) {
                const errors = response?.data?.errors;
                // Get an array of all values from the errors object
                const errorArrays = Object.values(errors);
                // Access the first array by default
                const firstArray = errorArrays[0];
                // console.log('firstArray', firstArray);
                globalNotifyError(firstArray[0]);
                return response;
            }
        } else {
            return response;
        }
    }

    // Handle specific status codes here
    if (response.data.meta.code === 404) {
        // Handle 400 Bad Request
        globalNotifyError('The page not found ! 404');
    } else if (response.data.meta.code === 500) {
        // Handle 500 Internal Server Error
        globalNotifyError('Oops..! Something went wrong.');
    } else if (response.data.meta.code === 429) {
        globalNotifyError('Oops..! Too many requests !');
        return response;
    } else if (response.data.meta.code === 451) {
        handleTokenSwap();

        MPinStatusChecker();
        globalNotifyError('Session Timeout');
    }
});
//commment
