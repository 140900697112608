/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
const employeeRole = localStorage.getItem('employeeRole');
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const employee = {
    id: 'newUtilities',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'employee/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons2',
            title: 'Profile',
            type: 'item',
            url: 'employee/profile',
            breadcrumbs: true
        },
        // {
        //     id: 'icons2211',
        //     title: 'Merchants',
        //     type: 'collapse',
        //     children: [
        //         {
        //             id: 'sample-page211',
        //             title: 'Create Merchants',
        //             type: 'item',
        //             url: '/employee/Create-Merchant',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tabler-icons521',
        //             title: 'List Merchants',
        //             type: 'item',
        //             url: '/employee/list-merchant',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        ...(employeeRole === 'teammanager' ? [{
            id: 'icons2231',
            title: 'TL',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3120',
                    title: 'TL',
                    type: 'item',
                    url: 'employee/tl',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53221',
                    title: 'List TL',
                    type: 'item',
                    url: '/employee/listtl',
                    breadcrumbs: false
                }
            ]
        }] : []),
        ...(employeeRole === 'teammanager' || employeeRole === 'teamleader' ? [{
            id: 'icons22111',
            title: 'Employee',
            type: 'collapse',
            children: [
                {
                    id: 'sample-page2111',
                    title: 'Create Employee',
                    type: 'item',
                    url: '/employee/createemployee',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons521',
                    title: 'List Employee',
                    type: 'item',
                    url: '/employee/listemployee',
                    breadcrumbs: false
                }
            ]
        }] : [])
    ]
};


export default employee;
